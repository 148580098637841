<!--  -->
<template>
  <van-uploader
    v-model="fileList"
    :after-read="afterRead"
    multiple
    :max-count="max"
    v-bind="attrs"
    @delete="handleDelte"
  />
</template>

<script>
// 踩坑，vant把value接收改成fileList，:value传递下去没用，要用:fileList传递

export default {
  name: "FileImgUploadVant",
  inheritAttrs: false,
  props: {
    value: Array,
    sourceType: {
      type: Boolean,
      default: true,
    },
    max: {
      default: 9,
    },
    deleteable: {
      type: Boolean,
      default: true,
    },
    isPath: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    fileList: {
      // ["url"] |  [{filePath:"url",fileName:"name",fileId:"1"}]
      get() {
        return this.value.map((el) => {
          return { url: this.$$.getUrl(this.isPath ? el : el.filePath) }
        })
      },
      set() {},
    },
    // capture() {
    //   return this.sourceType ? "" : "camera";
    // },
    attrs() {
      let attrs = { ...this.$attrs }
      if (!this.sourceType) {
        attrs.capture = "camera"
      }
      return attrs
    },
  },
  watch: {
    value() {
      this.getValueType()
    },
  },
  methods: {
    handleDelte(_, { index }) {
      let data = [...this.value]
      data.splice(index, 1)
      this.$emit("input", data)
    },
    getValueType() {
      if (this.value.length) {
        if (new Set(this.value.map((el) => typeof el)).size != 1) {
          throw "value数组必须给相同类型的值"
        }
      }
    },
    upload2Own(file) {
      return new Promise((resolve) => {
        this.$post
          .post(`/File/UploadByBase64`, {
            data: file.content,
          })
          .then((res) => {
            if (this.isPath) {
              res = res.filePath
            }
            resolve(res)
          })
          .catch((e) => {
            e.hide()
            this.$dialog.alert({
              message: "上传出错，请检查网络或重试！",
            })
            // reject(e);
            console.warn(e)
          })
      })
    },
    async afterRead(file) {
      let loading = this.$loading({ text: "文件上传中" })
      let files = this.$$.typeof(file) !== "array" ? [file] : file
      let res = await Promise.all(files.map((o) => this.upload2Own(o)))

      this.$emit("input", this.value.concat(res))
      loading.close()
    },
  },
}
</script>

<style lang="scss" scoped></style>
