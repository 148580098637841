"use strict"

import Vue from "vue"
import axios from "axios"
import ShowError from "./ShowError"
import qs from "qs"

let config = {
  //  process.env.VUE_APP_URL
  // baseURL: process.env.NODE_ENV === "production" ? "/api" : "/",
  // baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_URL : "/",
  baseURL: "/",
  timeout: 2 * 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
}

function addToken(config) {
  const token = window.abp.auth.getToken()
  const refreshToken = window.abp.auth.getRefreshToken()
  if (token) config.headers.Authorization = "Bearer " + token
  if (refreshToken) config.headers["X-Authorization"] = "Bearer " + refreshToken
  config.headers[".AspNetCore.Culture"] = window.abp.utils.getCookieValue("Abp.Localization.CultureName")
  config.headers["Abp.TenantId"] = window.abp.multiTenancy.getTenantIdCookie()
}
function refreshToken(res) {
  // 读取响应报文头 token 信息
  const accessToken = res.headers["access-token"]
  const refreshAccessToken = res.headers["x-access-token"]
  // 判断是否是无效 token
  if (accessToken === "invalid_token") {
    abp.utils.deleteCookie(abp.auth.tokenCookieName, abp.appPath)
    abp.utils.deleteCookie(abp.auth.refreshTokenCookieName, abp.appPath)
  }
  // 判断是否存在刷新 token，如果存在则存储在本地
  else if (refreshAccessToken && accessToken && accessToken !== "invalid_token") {
    abp.utils.setCookieValue(abp.auth.tokenCookieName, accessToken, null, abp.appPath, abp.domain)
    abp.utils.setCookieValue(abp.auth.refreshTokenCookieName, refreshAccessToken, null, abp.appPath, abp.domain)
  }
}
function handleError(error) {
  // 利用event loop ，等catch处理完后再进入预设处理，当调用hideNormalError时候，就不进行默认处理
  // ex :this.$ajax('/abp/servies/get..').catch((err,hide)=>{hide()})
  let isShowNormalError = true
  const hideNormalError = () => (isShowNormalError = false)
  // 当前页禁止默认错误处理，一般是用于大屏页，即使报错了也不展示弹窗
  // if (store.state.setting.hideAjaxDefaultError) isShowNormalError = false

  setTimeout(() => {
    if (isShowNormalError) {
      new ShowError(error)
    }
  })

  return Promise.reject({ err: error, hide: hideNormalError }) // 在error.response上添加方法
}
// ------------------------------------------------------------
const _axios = axios.create(config)
_axios.interceptors.request.use(
  (config) => {
    // console.log(config, axios.defaults)
    addToken(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
_axios.interceptors.response.use((response) => {
  refreshToken(response)
  // 成功后得到的值，在getpageresult接口中，默认只把data丢出去
  if (response.config.url.includes("GetPageResult")) {
    return response.data
  } else if (response.data.success) {
    return response.data.result
  } else {
    return response.data
  }
}, handleError)
// ---------------------getPageResult-----------------------
const _getPageResult = async function (url, n, ...args) {
  let str = ""
  if (n && typeof n === "object") {
    str = abp.utils.buildQueryString(
      Object.keys(n).map((key) => {
        let value = key === "searchKeys" && typeof n.searchKeys !== "string" ? JSON.stringify(n[key]) : n[key]
        return { name: key, value: value }
      })
    )
  }
  return await _axios.apply(null, [url + str, ...args])
}
// ---------------------post-发送formData------------------

const _post = axios.create({
  baseURL: config.baseURL,
  timeout: config.timeout,
  header: {
    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
})

_post.interceptors.request.use(
  (config) => {
    addToken(config)
    config.data = qs.stringify(config.data) // 转为formdata数据格式
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)
_post.interceptors.response.use((response) => {
  refreshToken(response)
  // Do something with response data
  return response.data.result
}, handleError)
// ----------------------------oaAjax-----------------------

const _oaAjax = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: config.timeout,
  withCredentials: config.withCredentials,
})
_oaAjax.interceptors.request.use(
  (config) => {
    addToken(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
_oaAjax.interceptors.response.use((response) => {
  refreshToken(response)
  // 成功后得到的值，在getpageresult接口中，默认只把data丢出去
  // if (response.config.url.includes("GetPageResult")) {
  //   return response.data
  // } else
  if (response.data.success) {
    return response.data.result
  } else {
    return response.data
  }
}, handleError)

// ---------------------------------------------------

_axios.defaults.withCredentials = true
_post.defaults.withCredentials = true
_oaAjax.defaults.withCredentials = true

let Plugin = {}
Plugin.install = function (Vue) {
  window.ajax = _axios
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return _axios
      },
    },
    $ajax: {
      get() {
        return _axios
      },
    },
    $oaAjax: {
      get() {
        return _oaAjax
      },
    },
    $post: {
      get() {
        return _post
      },
    },
    $getPageResult: {
      get() {
        return _getPageResult
      },
    },
  })
}

Vue.use(Plugin)

export { _axios as ajax, _getPageResult as getPageResult }
