import $$ from "@/plugins/util"
const { isWX, isDD } = $$

export default function getSdk() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let config
    try {
      if (isWX) {
        config = await import("./wxSdk")
        let wx = await config.default
        resolve(wx)
      } else if (isDD) {
        config = await import("./ddSdk")
        let dd = await config.default
        resolve(dd)
      } else {
        resolve(false)
      }
    } catch (e) {
      reject(e)
    }
  })
}

export { isWX, isDD }
