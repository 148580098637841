<!-- 通用返回标题主页 -->
<template>
  <van-sticky>
    <van-nav-bar
      :title="title"
      left-text="返回"
      :right-text="rightText"
      left-arrow
      @click-left="back"
      @click-right="rightClick"
    >
      <template v-if="$slots.right" #right><slot name="right" /></template>
      <template v-if="$slots.title" #title><slot name="title" /></template>
      <template v-if="$slots.left" #left><slot name="left" /></template>
    </van-nav-bar>
  </van-sticky>
</template>

<script>
import Vue from "vue"
import { NavBar, Sticky } from "vant"
Vue.use(Sticky).use(NavBar)
export default {
  props: {
    title: String,
    isFactory: {
      default: true,
      type: Boolean,
    },
    isProcessor: {
      default: false,
      type: Boolean,
    },
    rightText: {
      default: "主页",
      type: String,
    },
  },
  data() {
    return {}
  },
  methods: {
    back() {
      this.$router.go(-1)
    },

    rightClick() {
      if (this.rightText !== "主页" || (this.$slots.right && this.$slots.right.length)) {
        this.$emit("click-right")
        return
      }
      // this.$router.push("/assign");
      // 如果是加工点
      if (this.isProcessor) {
        return (location.href = this.$processHomeUrl)
      }
      this.$router.push("/")
      // else if (this.isFactory && abp.$power.isFactory) {
      //   //如果是进模具厂，没特性不行
      //   this.$router.push(this.isFactory ? this.$factoryHomeUrl : this.$processHomeUrl);
      // }
    },
  },
}
</script>

<style lang="scss" scoped></style>
