import Vue from "vue"
import VueI18n from "vue-i18n"
import zhCN from "vxe-table/lib/locale/lang/zh-CN"
Vue.use(VueI18n)

const messages = {
  // eslint-disable-next-line camelcase
  zh_CN: {
    ...zhCN,
  },
}

export default new VueI18n({
  locale: "zh_CN",
  messages,
})
