import Vue from "vue"
import {
  // 实例
  VXETable,
  // 模块
  Icon,
  Column,
  Colgroup,
  Header,
  Footer,
  Filter,
  Tooltip,
  Grid,
  Menu,
  Toolbar,
  Pager,
  Form,
  FormItem,
  Checkbox,
  Radio,
  Input,
  Select,
  Button,
  Modal,
  Edit,
  Export,
  Keyboard,
  Validator,
  Textarea,
  Switch,
  // 核心
  Table,
} from "vxe-table"
import i18n from "@/plugins/i18n"

// 先安装依赖模块
Vue.use(Icon)
Vue.use(Column)
Vue.use(Colgroup)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Filter)
Vue.use(Tooltip)
Vue.use(Grid)
Vue.use(Menu)
Vue.use(Toolbar)
Vue.use(Pager)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Input)
Vue.use(Select)
Vue.use(Button)
Vue.use(Modal)
Vue.use(Edit)
Vue.use(Export)
Vue.use(Keyboard)
Vue.use(Validator)
Vue.use(Switch)
Vue.use(Textarea)
// 再安装核心库
Vue.use(Table)

Vue.prototype.$XModal = VXETable.modal

VXETable.setup({
  i18n: (key, args) => i18n.t(key, args),
  size: "mini", // 全局尺寸
  zIndex: 100, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡
  version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
  table: {
    // showHeader: true,
    // keepSource: false,
    // showOverflow: true,
    // showHeaderOverflow: true,
    // showFooterOverflow: true,
    highlightHoverRow: true,
    highlightCurrentRow: true,
    // size: "mini",
    resizable: true,
    stripe: true,
    border: true,
    optimization: {
      // animat: true,
      // cloak: false,
      // delayHover: 250,
      scrollX: {
        gt: 60,
      },
      scrollY: {
        gt: 100,
      },
    },
    rowId: "id", // 行数据的唯一主键字段名
  },
  grid: {
    resizable: false,
    // size: "mini",
    proxyConfig: {
      autoLoad: true,
      message: true,
      props: {
        list: null, // 用于列表，读取响应数据
        result: "data", // 用于分页，读取响应数据
        total: "count", // 用于分页，读取总条数
      },
    },
    pagerConfig: {
      perfect: true,
      pageSize: 50,
      pageSizes: [50, 100, 200, 500, 1000],
      layouts: ["PrevJump", "PrevPage", "Jump", "PageCount", "NextPage", "NextJump", "Sizes", "Total"],
    },
  },
  // pager: {
  //   perfect: true,
  //   pageSize: 50,
  //   pagerCount: 10,
  //   pageSizes: [50, 100, 200, 500, 1000],
  //   layouts: ["PrevJump", "PrevPage", "Jump", "PageCount", "NextPage", "NextJump", "Sizes", "Total"]
  // }
})

// ...
import VXETablePluginElement from "vxe-table-plugin-element"
import "vxe-table-plugin-element/dist/style.css"
VXETable.use(VXETablePluginElement)

// import FilterContent from "./components/FilterContent.vue";
// Vue.component(FilterContent.name, FilterContent);
// // 创建一个支持列内容的筛选
// VXETable.renderer.add("FilterContent", {
//   // 不显示底部按钮，使用自定义的按钮
//   isFooter: false,
//   // 筛选模板
//   renderFilter(h, renderOpts, params) {
//     return [<filter-content params={params}></filter-content>];
//   },
//   // 重置数据方法
//   filterResetMethod({ options }) {
//     options.forEach((option) => {
//       option.data = { vals: [], sVal: "" };
//     });
//   },
//   // 筛选数据方法
//   filterMethod({ option, row, column }) {
//     const { vals } = option.data;
//     const cellValue = XEUtils.get(row, column.property);
//     return vals.some((val) => val === cellValue);
//   },
// });
