<!--  -->
<template>
  <div class="r-card1-wrap">
    <header>
      <h3>
        <slot name="title" :title="title">{{ title }}</slot>
      </h3>
    </header>
    <main>
      <slot>
        <ul>
          <li v-for="(item, index) in items" :key="index" class="r-card1-list">
            <slot name="label" :list="item">
              <span>{{ item.label }}</span>
            </slot>
            <slot name="value" :list="item">
              <template v-if="item.type === 'imgs'">
                <van-image
                  v-for="(img, i) in item.value"
                  :key="i"
                  width="50"
                  height="50"
                  :src="img"
                  class="ml-2"
                  @click="previewImage(i, item.value)"
                />
              </template>
              <p v-else v-html="item.value"></p>
            </slot>
          </li>
        </ul>
      </slot>
    </main>
  </div>
</template>

<script>
import ImgPreview from "@/plugins/ImgPreview"
export default {
  props: {
    title: String,
    items: { type: Array, default: () => [] },
  },
  data() {
    return {}
  },
  methods: {
    previewImage(i, imgs) {
      // eslint-disable-next-line new-cap
      ImgPreview({
        images: imgs,
        startPosition: i,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.r-card1-wrap {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
  background: #fff;
  padding-bottom: 10px;
  header h3 {
    padding-left: 20px;
    margin-left: 3px;
    line-height: 1.5;
    position: relative;
    font-size: 16px;
    // margin: 8px 0 8px 3px;
    &:before {
      content: "";
      position: absolute;
      background-color: #38b44c;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      z-index: 1;
    }
  }
  main {
    .r-card1-list {
      overflow: hidden;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      line-height: 2;
      & > span {
        color: #757f89;
        display: block;
        float: left;
      }
      & > p {
        width: 60%;
        float: right;
        color: #495056;
      }
    }
  }
}
</style>
