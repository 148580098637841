<template>
  <div class="el-upload el-upload--text">
    <!-- <v-overlay :value="percentMsg">
      <v-progress-circular
        v-if="
        "
        :indeterminate="false"
        :rotate="0"
        :size="200"
        :value="percentMsg.percent"
        :width="6"
        color="white"
      >
        <div v-html="percentMsg.msg"></div>
      </v-progress-circular>
    </v-overlay> -->
    <el-upload
      v-bind="$attrs"
      :action="action"
      :headers="headers"
      :accept="isImage ? 'image/*' : ''"
      :drag="!simple"
      :data="aliOssHeader"
      :show-file-list="showFileList"
      :limit="tLimit"
      :multiple="multiple"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-progress="handleProgress"
      class="text-left w-100"
      v-on="$listeners"
    >
      <slot>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
      </slot>
      <slot name="tip">
        <div v-if="!simple" slot="tip" class="el-upload__tip">文件上传不可超过{{ size }}MB{{ limitTip }}</div>
      </slot>
    </el-upload>
    <div v-if="!showFileList && !$slots.list && !simple" class="text-left">
      <FileListShow :file-list="value" @remove="handleRemove({ url: $event.filePath })"></FileListShow>
    </div>
    <slot v-else name="list"></slot>
  </div>
</template>
<script>
import { toFixed } from "@/core/utils"
import { getSignature, getFileNameUUID, upload2Own } from "@/core/utils/ali-oss"
export default {
  name: "UploadFilesLgOss",
  props: {
    simple: Boolean,
    value: Array,
    size: {
      default: 600,
      type: Number,
    },
    showFileList: {
      type: Boolean,
      default: false,
    },
    limit: Number,
    multiple: {
      default: true,
      type: Boolean,
    },
    isImage: Boolean,
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      headers: { "X-XSRF-TOKEN": abp.security.antiForgery.getToken(), Authorization: "Bearer " + abp.auth.getToken() },
      fileList: [],
      fileCache: [],
      // percent: null,
      startTime: null,
      activeLen: null,
      progressPercent: {},

      aliOss: null,
      aliOssHeader: null,
      activeFile: null,
      action: "",
    }
  },
  computed: {
    percentMsg() {
      let ins = this.progressPercent
      if (Object.keys(ins).length === 0) return null
      let len = this.activeLen
      let data = Object.keys(ins)
        .map((key) => ins[key])
        .reduce((a, b) => {
          return {
            loaded: a.loaded + b.loaded,
            total: a.total + b.total,
            percent: a.percent + b.percent,
          }
        })
      let percent = data.loaded / data.total
      percent = Math.ceil(percent * 100)
      percent = percent > 99 ? 99 : percent
      // console.log(len, percent, data.percent / len);

      let spead = toFixed(data.loaded / ((+new Date() - this.startTime) / 1000), 1)
      return {
        percent: percent,
        msg: [
          `共计${len}个正在上传`,
          `${toFixed(data.loaded, 1)} / ${toFixed(data.total, 1)} MB (${percent}%)`,
          ` 平均网速: ${spead}MB/s`,
        ].join("<br>"),
      }
    },
    limitTip() {
      return this.tLimit ? `，限制上传${this.tLimit}个文件` : ""
    },
    tLimit() {
      if (this.limit <= 0) return void 0
      return this.limit ?? (this.multiple ? void 0 : 1)
    },
  },
  watch: {
    value: "setFileListByValue",
  },
  created() {
    this.setFileListByValue()
  },
  methods: {
    // 重置token，下次来可能过期了
    resetSignature() {
      this.aliOss = null
      this.activeFile = null
    },
    async getSignature() {
      let aliyunOssToken = await getSignature()
      this.action = aliyunOssToken.host

      this.aliOss = {
        policy: aliyunOssToken.policy,
        OSSAccessKeyId: aliyunOssToken.accessId,
        // eslint-disable-next-line camelcase
        success_action_status: 200,
        signature: aliyunOssToken.signature,

        name: null,
        key: null,
        dir: aliyunOssToken.dir, // 这个dir是路径，临时存放，上传时候不需要的
      }
    },
    handleProgress(ev, file, fileList) {
      let loaded = ev.loaded / 1024 / 1024,
        total = ev.total / 1024 / 1024
      let percent = Math.ceil(ev.percent)
      percent = percent > 99 ? 99 : percent
      this.activeLen = fileList.filter((el) => el.status !== "success").length
      this.$set(this.progressPercent, file.uid, {
        total,
        loaded,
        percent,
      })
    },
    async beforeUpload(file) {
      await this.getSignature()
      let fullName = this.aliOss.dir + getFileNameUUID(file.name)
      file.fullName = fullName
      this.aliOssHeader = Object.assign({}, this.aliOss, {
        name: file.name,
        key: fullName,
        dir: null,
      })

      this.startTime = +new Date()
      const isLt2M = file.size / 1024 / 1024 < this.size
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过" + this.size + "MB!")
      }

      return isLt2M
    },
    handleRemove(file) {
      var val = this.value.filter((el) => el.filePath !== file.url)
      this.$emit("input", val)
      this.$emit("change", val)
      let removeFile = this.value.filter((el) => el.filePath === file.url)
      if (!removeFile[0]) throw new Error("删除出现异常")
      this.$emit("remove", removeFile[0])
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async upload2Own(fileCahce) {
      let arr = fileCahce.map((file) => {
        return {
          fullName: file.fullName,
          name: file.name,
          size: file.size,
        }
      })
      let res = await upload2Own(arr)
      res.map((o) => {
        o.creationTime = this.$dayjs().format("YYYY-MM-DD HH:mm:ss")
        o.creatorUserName = abp.session.user?.name
      })
      return res
    },
    async handleSuccess(response, file, fileList) {
      // 缓存起来只有当全部成功才向上抛出
      this.fileCache = this.fileCache.concat(file.raw)
      if (fileList.every((el) => el.status === "success")) {
        // console.log(this.value);
        let res = await this.upload2Own(this.fileCache)
        this.$message({ type: "success", message: "文件上传成功！" })
        this.$emit("input", this.value.concat(res))
        this.$emit("change", this.value.concat(res))
        this.$emit("add", res)
        this.fileCache = []
        this.resetSignature()
        this.afterUpload()
      }
      // error: null
      // result: {success: true, filePath: "https://itianwangmlmwtest.oss-cn-shanghai.aliyuncs…20/05/05/d78bc16f-28e4-4767-ac4d-86f95cf935bd.jpg", fileName: "006cqKYCly1g3mlvv6kr4j30j60j7ncm.jpg", fileSize: 74.3525390625, msg: null, …}
      // success: true
      // targetUrl: null
      // unAuthorizedRequest: false
    },
    handleError() {
      this.$message({ type: "error", message: "文件上传失败！" })
      this.afterUpload()
    },
    afterUpload() {
      this.progressPercent = {}
      this.startTime = null
      this.activeLen = null
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.tLimit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    setFileListByValue() {
      if (!this.value) {
        this.$emit("input", [])
        return
      }
      this.fileList = this.value
        ? this.value.map((file) => ({
            name: file.fileName || file.filePath,
            url: this.$$.getUrl(file.filePath),
            uid: file.fileId || file.filePath,
            creationTime: file.creationTime,
            creatorUserName: file.creatorUserName,
          }))
        : []
    },
  },
}
</script>
