const files = require.context(".", true, /\.js$/)

var configArray = []
var menus = {}

files.keys().forEach((key) => {
  if (key === "./index.js") return
  const file = files(key)
  configArray = configArray.concat(file.default)
  if (menus[file.name]) {
    menus[file.name] = menus[file.name].concat(file.default)
  } else {
    menus[file.name] = file.default
  }
})
export default configArray
export { menus }
