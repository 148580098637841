<template>
  <div>
    <header>
      <img alt="master logo" src="@/assets/master-logo1.png" />
      <el-button type="text" @click="logout">退出</el-button>
    </header>
    <el-container>
      <el-aside width="200px" class="side overflow-x-hidden">
        <el-menu
          v-model="route"
          style="min-height: calc(100vh - 40px)"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
        >
          <el-menu-item index="/product/BOMList/index">
            <i class="el-icon-menu"></i>
            <span slot="title">BOM</span>
          </el-menu-item>
          <!-- <el-menu-item index="/product/progress/index">
            <i class="el-icon-menu"></i>
            <span slot="title">生产进度</span>
          </el-menu-item> -->
          <el-menu-item index="/product/plate/all">
            <i class="el-icon-menu"></i>
            <span slot="title">版号管理</span>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>报工</span>
            </template>
            <el-menu-item index="/product/program/index">编程</el-menu-item>
            <el-menu-item index="/product/report/2">切料</el-menu-item>
            <el-menu-item index="/product/report/3">装配</el-menu-item>
            <el-menu-item index="/product/report/4">上下机</el-menu-item>
            <el-menu-item index="/product/report/5">交接班</el-menu-item>
            <el-menu-item index="/product/report/6">清角</el-menu-item>
            <el-menu-item index="/product/report/7">检验</el-menu-item>
            <el-menu-item index="/product/report/8">上架</el-menu-item>
            <el-menu-item index="/product/report/9">领用</el-menu-item>
            <el-menu-item index="/product/report/10">放电</el-menu-item>
            <el-menu-item index="/product/report/11">完成</el-menu-item>
          </el-submenu>
          <el-menu-item index="/product/process/index">
            <i class="el-icon-menu"></i>
            <span slot="title">报工记录</span>
          </el-menu-item>
          <el-menu-item index="/product/Reporterhours">
            <i class="el-icon-menu"></i>
            <span slot="title">员工工时</span>
          </el-menu-item>
          <!-- <el-menu-item index="/product/salary/index">
            <i class="el-icon-menu"></i>
            <span slot="title">员工绩效</span>
          </el-menu-item> -->
          <!-- <el-menu-item index="/product/Problem/index">
            <i class="el-icon-menu"></i>
            <span slot="title">问题点管理</span>
          </el-menu-item> -->
          <!-- <el-menu-item index="2">
        <i class="el-icon-menu"></i>
        <span slot="title">导航二</span>
      </el-menu-item>
      <el-menu-item index="3" disabled>
        <i class="el-icon-document"></i>
        <span slot="title">导航三</span>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-setting"></i>
        <span slot="title">导航四</span>
      </el-menu-item> -->
        </el-menu>
      </el-aside>
      <el-main style="height: calc(100vh - 40px)"><router-view /></el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      route: "/product/BOMList/index",
      name: "",
    }
  },
  methods: {
    logout() {
      this.$router.push("/login")
    },
  },
}
</script>
<style lang="scss" scoped>
header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 6px 13px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #2f4554;
  z-index: 999;
  img {
    height: 22px;
  }
}
.side {
  height: calc(100vh - 40px);
  background-color: #545c64;
  overflow-x: hidden;
}
</style>
