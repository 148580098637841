<script>
import UploadFilesLgInner from "../Upload/UploadFilesLgInner"
import UploadFilesLgOss from "../Upload/UploadFilesLgOss"
import abpUtil from "@/core/common/abpUtil"
export default {
  data() {
    return {}
  },
  render() {
    const data = {
      props: {
        ...this.$attrs,
      },
      on: {
        ...this.$listeners,
        click() {},
      },
    }
    return abpUtil.hasPlugin("oss") ? (
      <UploadFilesLgOss {...data}>{this.$slots.default}</UploadFilesLgOss>
    ) : (
      <UploadFilesLgInner {...data}>{this.$slots.default}</UploadFilesLgInner>
    )
  },
}
</script>

<style lang="scss" scoped></style>
