import Vue from "vue"
import Router from "vue-router"
import beforeEachHooks from "./beforeEachHooks"
import RoutesMapConfig from "./routes"
import commonRoutesMap from "./commonRoutes"
const routes = commonRoutesMap.concat(RoutesMapConfig)
Vue.use(Router)
// console.log(routes)
const routerInstance = new Router({
  mode: "history",

  base: "/servicepage/elea",
  linkActiveClass: "active",
  routes: routes,
})

Object.values(beforeEachHooks).forEach((hook) => {
  routerInstance.beforeEach(hook)
  // beforeEach返回一个用来移除此hook的函数
})

export default routerInstance
