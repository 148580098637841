<!--  -->
<template>
  <FileImgUploadWx v-if="$$.isWX && !h5" v-bind="$attrs" v-on="$listeners"></FileImgUploadWx>
  <FileImgUploadVant v-else v-bind="$attrs" v-on="$listeners"></FileImgUploadVant>
</template>

<script>
import FileImgUploadVant from "@/components/util/FileImgUploadVant"
import FileImgUploadWx from "@/components/util/FileImgUploadWx"
export default {
  name: "FileImgUpload",
  components: { FileImgUploadWx, FileImgUploadVant },
  props: {
    h5: Boolean,
  },
}
</script>

<style lang="scss" scoped></style>
