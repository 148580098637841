import Vue from "vue"
// true是PC端，false是手机端
let isPC = (function () {
  var userAgentInfo = navigator.userAgent
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
})()
let isProduction = process.env.NODE_ENV === "production"
let isWX = (() => {
  var ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("micromessenger") != -1
})()
let isDD = (() => {
  var ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("dingtalk") != -1
})()
class UtilClass {
  // 拷贝
  extend(...args) {
    let options,
      name,
      src,
      srcType,
      copy,
      copyIsArray,
      clone,
      target = args[0] || {},
      i = 1,
      length = args.length,
      deep = false
    if (typeof target === "boolean") {
      deep = target
      target = args[i] || {}
      i++
    }
    if (typeof target !== "object" && typeof target !== "function") {
      target = {}
    }
    if (i === length) {
      target = this
      i--
    }
    for (; i < length; i++) {
      if ((options = args[i]) !== null) {
        for (name in options) {
          src = target[name]
          copy = options[name]
          if (target === copy) {
            continue
          }
          srcType = Array.isArray(src) ? "array" : typeof src
          if (deep && copy && ((copyIsArray = Array.isArray(copy)) || typeof copy === "object")) {
            if (copyIsArray) {
              copyIsArray = false
              clone = src && srcType === "array" ? src : []
            } else {
              clone = src && srcType === "object" ? src : {}
            }
            target[name] = this.extend(deep, clone, copy)
          } else if (copy !== undefined) {
            target[name] = copy
          }
        }
      }
    }
    return target
  }
  // 判断类型
  typeof(obj) {
    var class2type = {}
    // 生成class2type映射
    "Boolean Number String Function Array Date RegExp Object Error".split(" ").map((item) => {
      class2type["[object " + item + "]"] = item.toLowerCase()
    })
    if (obj == null) {
      return obj + ""
    }
    return typeof obj === "object" || typeof obj === "function"
      ? class2type[Object.prototype.toString.call(obj)] || "object"
      : typeof obj
  }
  // 防抖函数
  // (延迟执行的函数,延迟时间)
  debounce(fn, interval = 300) {
    let timeout = null
    return function () {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        fn.apply(this, arguments)
      }, interval)
    }
  }
  // 获取queryString
  getUrlParam(name, url = window.location.search) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
    var r = url.substr(1).match(reg)
    if (r !== null) return decodeURIComponent(r[2])
    return null
  }
  // 取个uuid
  newid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
  }
  runAsync(p) {
    return new Promise((resolve, reject) => {
      const loading = Vue.prototype.$loading({
        lock: true,
        text: "请求中",
        // background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        p.then((res) => {
          resolve(res)
        })
          .catch((err) => {
            reject(err)
          })
          .finally(() => loading.close())
      } catch (err) {
        reject(err)
        loading.close()
      }
    })
  }
  replaceError(args, arg2) {
    if (!args || this.typeof(args) === "string") {
      args = {
        msg: args || "温馨提醒",
        detail: arg2 || "",
      }
    }
    let { msg = "", detail = "" } = args
    import("@/router").then((router) => {
      router.default.replace(`/error?msg=${encodeURIComponent(msg)}&detail=${encodeURIComponent(detail)}`)
    })
  }
  getUrl(str) {
    if (!str) return ""
    if (str.startsWith("http")) return str
    return (isProduction ? location.origin : process.env.VUE_APP_URL) + str
  }
  abp = window.abp
  url = isProduction ? "" : process.env.VUE_APP_URL // 开发为62335，生产为空
  originUrl = isProduction ? location.origin : process.env.VUE_APP_URL
  isProduction = isProduction
  isPC = isPC
  isWX = isWX
  isDD = isDD
  isIframe = window !== top
  query = document.querySelector.bind(document)
  queryAll = document.querySelectorAll.bind(document)
  fromId = document.getElementById.bind(document)
  fromClass = document.getElementsByClassName.bind(document)
  fromTag = document.getElementsByTagName.bind(document)
  jsonCopy = (d) => JSON.parse(JSON.stringify(d))
  customerServicePhone = "15988939422，15906863922"
}
let $$ = new UtilClass()
// window.$$ = $$;
export default $$
