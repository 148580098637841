<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// import { useAvatarStore } from "@/store/avatar"
// import { useManageStore } from "@/store/manage"
import { useMobileStore } from "@/store/mobile"
import { debounce } from "@/core/utils"
export default {
  name: "App",
  data() {
    return {
      route: "/product/BOMList/index",
    }
  },
  async mounted() {
    // useAvatarStore().init()
    // useManageStore().init()
    const { setMobile } = useMobileStore()
    window.addEventListener(
      "resize",
      debounce(() => {
        setMobile()
      }, 100)
    )
  },
}
</script>
