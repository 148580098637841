import Vue from "vue"
const $post = Vue.prototype.$post
// const $ajax = Vue.prototype.$ajax;

export async function getFileByIds(ids, sync = true) {
  if (sync) {
    return ids.map((id) => ({ filePath: Vue.prototype.$$.getUrl(`/File/Thumb/${id}`), fileName: `/File/Thumb/${id}` }))
  } else {
    return await Vue.prototype.$ajax(
      `/api/services/app/File/GetListByIds` +
        abp.utils.buildQueryString([
          {
            name: "primaries",
            value: ids,
          },
        ])
    )
  }
}
export async function upload(val) {
  let file
  if (typeof val === "object") {
    file = val.file
  } else {
    file = val
  }
  return await $post.post(`/File/Upload`, { file })
}
export async function uploadByBase64(val) {
  let data, name
  if (typeof val === "object") {
    data = val.data
    name = val.name
  } else {
    data = val
  }
  return await $post.post(`/File/UploadByBase64`, {
    data,
    name,
  })
}
