import Layout from "@/views/Common/Layout.vue"
export default [
  {
    path: "/product",
    component: Layout,
    name: "product",
    children: [
      {
        path: "home",
        meta: {
          title: "首页",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Home/index.vue"),
      },
      {
        path: "progress/index",
        meta: {
          title: "生产进度",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Progress/index"),
      },
      {
        path: "BOMList/index",
        meta: {
          title: "BOM",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/BOMList/index"),
      },
      {
        path: "plate/index",
        meta: {
          title: "版号管理",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Plate/index"),
      },
      {
        path: "plate/all",
        meta: {
          title: "版号管理",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Plate/All.vue"),
      },
      {
        path: "process/index",
        meta: {
          title: "报工管理",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Process/index"),
      },
      {
        path: "report/1",
        meta: {
          title: "编程",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/1.vue"),
      },
      {
        path: "report/2",
        meta: {
          title: "切料",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/2.vue"),
      },
      {
        path: "report/3",
        meta: {
          title: "装配",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/3.vue"),
      },
      {
        path: "report/4",
        meta: {
          title: "上下机",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/4.vue"),
      },
      {
        path: "report/5",
        meta: {
          title: "交接班",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/5.vue"),
      },
      {
        path: "report/6",
        meta: {
          title: "清角",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/6.vue"),
      },
      {
        path: "report/7",
        meta: {
          title: "检验",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/7.vue"),
      },
      {
        path: "report/8",
        meta: {
          title: "上架",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/8.vue"),
      },
      {
        path: "report/9",
        meta: {
          title: "领用",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/9.vue"),
      },
      {
        path: "report/10",
        meta: {
          title: "放电",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/10.vue"),
      },
      {
        path: "report/11",
        meta: {
          title: "完成",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/11.vue"),
      },
      {
        path: "Reporterhours",
        meta: {
          title: "绩效管理",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Reporterhours/index.vue"),
      },
      {
        path: "Problem/index",
        meta: {
          title: "问题点管理",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Problem/index.vue"),
      },
      {
        path: "program/index",
        meta: {
          title: "编程",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Program/index.vue"),
      },
      {
        path: "salary/index",
        meta: {
          title: "绩效",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Salary/index.vue"),
      },
    ],
  },
  {
    path: "/product/processtask/index",
    meta: {
      title: "加工绑定",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Processtask/index.vue"),
  },
  {
    path: "/mobile/report",
    meta: {
      title: "手机报单",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/Mobile/index.vue"),
  },
  {
    path: "/mobile/reports",
    meta: {
      title: "手机报单",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Product/Report/Mobile/Reports"),
  },
]
export const name = "生产管理"
