let config = [
  {
    meta: {
      title: "首页",
    },
    path: "/",
    redirect: "/product/BOMList/index",
  },
  // {
  //   name: "test",
  //   path: "/test",
  //   meta: {
  //     title: "Test",
  //   },
  //   component: () => import("@/views/Common/Test.vue"),
  // },
  {
    meta: {
      title: "404",
    },
    path: "*",
    component: () => import("@/views/Common/404"),
  },
  {
    name: "login",
    meta: {
      title: "登录",
    },
    path: "/Login",
    component: () => import("@/views/Common/Login"),
    props: (route) => ({
      returnUrl: route.query.returnUrl,
    }),
  },
]
export default config
