import { getUrlParam } from "./index"

// true是PC端，false是手机端
export const isPC = (function () {
  var userAgentInfo = navigator.userAgent
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
})()

export const isPrivate = (name) => name === process.env.VUE_APP_Private

export const isProduction = process.env.NODE_ENV === "production"

let isWX = (() => {
  var ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("micromessenger") != -1
})()
let isDD = (() => {
  var ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("dingtalk") != -1
})()

class UtilClass {
  noop = function () {}

  // 判断类型
  typeof(obj) {
    var class2type = {}
    // 生成class2type映射
    "Boolean Number String Function Array Date RegExp Object Error".split(" ").map((item) => {
      class2type["[object " + item + "]"] = item.toLowerCase()
    })
    if (obj == null) {
      return obj + ""
    }
    return typeof obj === "object" || typeof obj === "function"
      ? class2type[Object.prototype.toString.call(obj)] || "object"
      : typeof obj
  }
  // 环境相关
  // url = isProduction ? process.env.VUE_APP_URL : "" // 开发为62335，生产为空
  url = ""
  getUrl(str, complete) {
    if (!str) return
    if (str.startsWith("http")) return str
    if (complete) return (process.env.VUE_APP_URL || location.origin) + str
    if (str.startsWith("/PC/") || isProduction) return location.origin + str
    return process.env.VUE_APP_URL + str
  }
  isPrivate = isPrivate
  isProduction = isProduction
  isPC = isPC
  isWX = isWX
  isDD = isDD
  isIframe = window !== top
  getUrlParam = getUrlParam
}
let $$ = new UtilClass()
// window.$$ = $$;
export default $$
