<template>
  <div>
    <el-upload
      v-bind="$attrs"
      :action="$rootUrl + '/File/Upload'"
      :headers="headers"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :before-upload="beforeAvatarUpload"
      :file-list="fileList"
      v-on="$listeners"
    >
      <template slot="trigger">
        <slot></slot>
      </template>
      <template #tip>
        <slot name="tip"></slot>
      </template>
    </el-upload>
  </div>
</template>
<script>
export default {
  props: {
    isImage: { type: Boolean, default: true },
    showLog: { type: Boolean, default: true },
    value: Array,
  },
  data() {
    return {
      headers: {
        "X-XSRF-TOKEN": abp.security.antiForgery.getToken(),
        Authorization: "Bearer " + abp.auth.getToken(),
      },
      fileList: [],
      fileCache: [],
      // percent: 100
    }
  },
  computed: {
    multiple() {
      var multiple = this.$attrs.multiple
      return typeof multiple === "boolean" ? multiple : true
    },
  },
  watch: {
    value: "setFileListByValue",
  },
  created() {
    this.setFileListByValue()
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 / 1024 < 1
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 1GB!")
      }
      return isLt2M
    },
    handleRemove(file) {
      var val = this.value.filter((el) => el.filePath != file.url)
      this.$emit("input", val)
      console.log(file)
    },
    handleSuccess(response, file, fileList) {
      // 缓存起来只有当全部成功才向上抛出
      this.fileCache = this.fileCache.concat(response.result)
      if (fileList.every((el) => el.status === "success")) {
        this.showLog && this.$message({ type: "success", message: "文件上传成功！" })
        this.$emit("input", this.value.concat(this.fileCache))
        this.$emit("change", this.fileCache)
        this.fileCache = []
      }
    },
    handleError() {
      this.$message({ type: "error", message: "文件上传失败！" })
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.$attrs.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    setFileListByValue() {
      if (!this.value) this.$emit("input", [])
      this.fileList = this.value
        ? this.value.map((file) => ({
            name: file.fileName || file.filePath,
            url: this.$$.getUrl(file.filePath),
            uid: file.fileId || file.filePath,
          }))
        : []
    },
  },
}
</script>
