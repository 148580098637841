// interface file {
//     images:array,
//     startPosition:number,
// }
// 使用了vant的格式，转换成需要带http的微信格式

import Vue from "vue"
import { ImagePreview } from "vant"
import filters from "@/filters"
function ImgPreview(options) {
  let $$ = Vue.prototype.$$,
    optionsType = $$.typeof(options)

  if (optionsType !== "array" && optionsType !== "object") {
    Vue.prototype.$notify("传入参数必须为数组或对象")
    return
  }

  if (optionsType === "array") {
    options.images = options
    options.startPosition = 0
  }
  options.images = options.images.map((url) => filters.urlIncludeHttp(url))

  // 移动端并且是生产环境，使用微信sdk
  if ($$.isWX) {
    Vue.prototype.$wx.previewImage({
      current: options.images[options.startPosition], // 当前显示图片的http链接
      urls: options.images, // 需要预览的图片http链接列表
    })
  } else {
    // eslint-disable-next-line new-cap
    ImagePreview({
      images: options.images,
      startPosition: options.startPosition,
    })
  }
}

export default ImgPreview
export const ImgPreviewOne = function (filePath) {
  if (!filePath) {
    return this.$toast.fail("暂无图片可展示")
  }
  // eslint-disable-next-line new-cap
  ImgPreview({
    images: [filePath],
    startPosition: 0,
  })
}
