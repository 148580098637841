import Vue from "vue"
import "./lib/abp/index.js"
import { createPinia, PiniaVuePlugin } from "pinia"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./plugins/axios/index.js"
import "./global.js"
import abpUtil from "./lib/abp/abpUtil"

import "./assets/scss/common.scss"
import "./assets/scss/tailwindcss.css"

import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI, { size: "mini" })

// VXE-table
import "xe-utils"
import "./plugins/vxe/table"

/* ------------------------Vue Global Components---全局组件------------------------------ */

const files = require.context("./components/Base", true, /\.vue$/)
files.keys().forEach((key) => {
  let componentName = key.replace(/^\.\//, "").replace(/\.\w+$/, "")
  let componentConfig = files(key)
  Vue.component(componentName, componentConfig.default || componentConfig)
})

/* ------------------------Vue Global Components------------------------------ */
import {
  Dialog,
  Toast,
  Notify,
  Icon,
  Button,
  Image,
  Cell,
  Field,
  CellGroup,
  Overlay,
  Picker,
  Search,
  List,
  Sticky,
  Tag,
  Popup,
  Form,
  DatetimePicker,
  Lazyload,
  ActionSheet,
  DropdownMenu,
  DropdownItem,
  Tab,
  Tabs,
  Switch,
  Rate,
  NoticeBar,
  Uploader,
  PullRefresh,
  Col,
  Divider,
  Calendar,
  Row,
  Skeleton,
  Tabbar,
  TabbarItem,
  Empty,
} from "vant"
// 全局注册

Vue.use(Button)
  .use(Icon)
  .use(Calendar)
  .use(ActionSheet)
  .use(Popup)
  .use(Sticky)
  .use(List)
  .use(Form)
  .use(DatetimePicker)
  .use(Dialog)
  .use(Notify)
  .use(Image)
  .use(Cell)
  .use(Toast)
  .use(Overlay)
  .use(CellGroup)
  .use(Picker)
  .use(Search)
  .use(Tag)
  .use(Lazyload)
  .use(Field)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Tab)
  .use(Tabs)
  .use(Switch)
  .use(Rate)
  .use(NoticeBar)
  .use(PullRefresh)
  .use(Col)
  .use(Row)
  .use(Divider)
  .use(Skeleton)
  .use(Uploader)
  .use(Tabbar)
  .use(Empty)
  .use(TabbarItem)

var dayjs = require("dayjs")
Vue.prototype.dayjs = dayjs

if (process.env.NODE_ENV === "development") {
  Vue.config.devtools = true
  Vue.config.productionTip = true
  Vue.mixin({
    methods: {
      logArgs(...args) {
        console.log("logArgs:", args)
      },
    },
  })
}
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

abpUtil.getAbpConfig().then(() => {
  window.app = new Vue({
    router,
    store,
    pinia,
    render: (h) => h(App),
  }).$mount("#app")
})
