import Vue from "vue"
import $$ from "@/core/utils/utilClass"
const ajax = Vue.prototype.$ajax
import { randomString } from "./index.js"
function getSuffix(filename = "") {
  let pos = filename.lastIndexOf(".")
  let suffix = ""
  if (pos !== -1) {
    suffix = filename.substring(pos)
  }
  return suffix
}
export function getFileNameUUID(filename) {
  return +new Date() + "_" + randomString(10) + getSuffix(filename)
}
export async function getSignature() {
  return await ajax("/api/services/filecloud/Oss/GetSignature")
}

// fullName: "files/2020/11/26/pYXJY83JPi.png"
// name: "1606292824570_dpwPJptMFE.png"
// size: 18888
export function upload2Own(arr) {
  return ajax.post(`/api/services/filecloud/Oss/UploadResult`, arr)
}

async function uploadOSS(formData, url, { progress } = {}) {
  let res = await ajax({
    url: url,
    headers: { "Content-Type": "multipart/form-data;boundary=" + new Date().getTime() },
    method: "post",
    withCredentials: false,
    data: formData,
    onUploadProgress: function (progressEvent) {
      $$.typeof(progress) === "function" && progress(progressEvent)
    },
  })

  return res
}

export default async function (file, option = {}) {
  try {
    let progress = option.progress

    let aliyunOssToken = await getSignature()

    let fileName = getFileNameUUID(file?.name)
    let keyValue = `${aliyunOssToken.dir}${fileName}`
    // 组装formdata
    let formdata = new FormData()
    formdata.append("name", file.name)
    formdata.append("key", keyValue)
    formdata.append("policy", aliyunOssToken.policy)
    formdata.append("OSSAccessKeyId", aliyunOssToken.accessId)
    formdata.append("success_action_status", 200)
    formdata.append("signature", aliyunOssToken.signature)
    formdata.append("file", file)

    await uploadOSS(formdata, aliyunOssToken.host, {
      progress,
    })

    return {
      filePath: aliyunOssToken.host + "/" + keyValue,
      fileName,
      fullname: keyValue,
      name: fileName,
      size: file.size,
    }
  } catch (error) {
    console.log(error)
  }
}
