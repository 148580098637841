<!--  -->
<template>
  <transition-group name="el-zoom-in-top" tag="ul" class="overflow-hidden">
    <li
      v-for="(file, index) in fileList"
      :key="file.fileId || index"
      class="fileupload-list-li d-flex jc-start ai-center"
      :class="small ? 'small-link' : ''"
    >
      <template v-if="!small">
        <div class="fileupload-list-imgwrap" :title="file.fileName">
          <el-image
            :src="$$.getUrl(file.filePath)"
            :alt="file.fileName"
            class="fileupload-list-img"
            :preview-src-list="fileList.map((el) => $$.getUrl(file.filePath))"
          >
            <div slot="error" class="image-slot">
              <upload-link
                :file="file"
                :open-file="openFile"
                class="fileupload-list-img-error text-ellipsis"
                :underline="false"
              >
                {{ getShortName(file.filePath) }}
              </upload-link>
            </div>
          </el-image>
        </div>
        <div class="flex-1 fileupload-list-msg">
          <div class="d-flex ai-center">
            <upload-link :file="file" class="mr-3" :open-file="openFile"></upload-link>
            <v-btn
              v-if="!hideRemove"
              class="mr-3"
              small
              text
              color="primary"
              @click="
                previewImage(
                  file.filePath,
                  fileList.map((o) => o.filePath)
                )
              "
            >
              预览
            </v-btn>
            <v-btn v-if="!hideRemove" class="mr-3" small text color="red" @click="$emit('remove', file)">删除</v-btn>
          </div>
          <el-row v-if="file.creationTime" class="text-grey-1 fs-md" no-gutters>
            <el-col :span="6" class="text-ellipsis">
              <small class="grey--text fs-sm">创建人：</small>
              {{ file.creatorUserName }}
            </el-col>
            <el-col :span="6" class="text-ellipsis">
              <small class="grey--text fs-sm">上传时间：</small>
              {{ file.creationTime | fromNow }}
            </el-col>
          </el-row>
        </div>
      </template>
      <div v-else class="d-flex jc-center ai-center">
        <!-- <el-link
          target="_blank"
          :href="$$.getUrl(file.filePath)"
          class="text-ellipsis link-filename"
          :title="file.fileName"
        >
          {{ file.fileName }}
        </el-link> -->
        <upload-link :file="file" class="mr-3" :open-file="openFile"></upload-link>
        <v-btn small icon color="red" @click="$emit('remove', file)"><v-icon>mdi-delete</v-icon></v-btn>
      </div>
    </li>
  </transition-group>
</template>

<script>
import { previewImage } from "@/core/utils"
export default {
  props: {
    fileList: Array,
    small: Boolean,
    hideRemove: Boolean,
    openFile: Function,
    documentType: Number,
  },
  methods: {
    previewImage,
    getShortName(filePath) {
      if (this.documentType === 3) {
        return "OA"
      }
      return filePath?.includes(".") ? filePath.replace(/.*\./, "") : `<i class="el-icon-document"></i>`
    },
  },
}
</script>

<style lang="scss">
.fileupload-list-li {
  &:first-child {
    margin-top: 10px;
  }
  &:not(.small-link) {
    padding: 10px 8px;
    margin: 10px 0;
    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
  border-radius: 5px;
  .fileupload-list-imgwrap {
    margin-right: 15px;
    width: 100px;
    background-color: rgba(9, 30, 66, 0.08);
    border-radius: 6px;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    .fileupload-list-img {
      border-radius: 6px;
      box-sizing: border-box;
      width: 100%;
      max-height: 80px;
      // line-height: 1;
      vertical-align: top;
      object-fit: cover;
      .fileupload-list-img-error {
        color: #5e6c84;
        font-size: 18px;
        font-weight: 700;
        line-height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        text-transform: uppercase;
        text-decoration: none;
        & > i {
          font-size: 24px;
        }
      }
    }
  }
  .fileupload-list-msg {
    width: calc(100% - 115px);
  }
  .link-filename {
    line-height: 1.2em;
  }

  &.small-link .link-filename {
    width: 150px;
  }
}
</style>
