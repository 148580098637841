<!--  -->
<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="van-uploader">
      <div class="van-uploader__wrapper">
        <div v-for="(img, index) in value" :key="isPath ? img : img.filePath" class="van-uploader__preview">
          <div
            class="van-image van-uploader__preview-image"
            style="overflow: hidden; border-radius: 4px"
            @click="previewImage(index)"
          >
            <img :src="isPath ? img : img.filePath" class="van-image__img" style="object-fit: cover" />
          </div>
          <i
            v-if="deletable"
            class="van-icon van-icon-clear van-uploader__preview-delete"
            @click="deleteImage(index)"
          ></i>
        </div>
        <div class="van-uploader__upload">
          <i class="van-icon van-icon-plus van-uploader__upload-icon"></i>
          <input type="button" class="van-uploader__input" @click="choose" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { Uploader } from "vant"

Vue.use(Uploader)
import ImgPreview from "@/plugins/ImgPreview"
import getSdk from "@/plugins/SDK"

export default {
  name: "FileImgUploadWx",
  props: {
    value: Array,
    sourceType: {
      type: Boolean,
      default: true,
    },
    max: {
      default: 9,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    isPath: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return { loading: false }
  },
  computed: {
    myReadonly() {
      return this.$attrs.readonly !== undefined || this.$attrs.disabled !== undefined ? true : false
    },
  },
  watch: {
    value() {
      this.getValueType()
    },
  },
  created() {
    this.getValueType()
  },
  methods: {
    postLog(str) {
      try {
        this.$ajax.post(`/api/services/app/Logger/Log?info=${str}`)
      } catch (e) {
        console.log(e)
      }
    },
    getValueType() {
      if (this.value.length) {
        if (new Set(this.value.map((el) => typeof el)).size != 1) {
          throw "value数组必须给相同类型的值"
        }
      }
    },
    deleteImage(index) {
      let data = [...this.value]
      data.splice(index, 1)
      this.$emit("input", data)
    },
    previewImage: function (index) {
      // eslint-disable-next-line new-cap
      ImgPreview({
        images: this.value.map((el) => el.filePath),
        startPosition: index,
      })
    },
    async upload2Own(serverIds) {
      try {
        let res = await this.$ajax.post("/api/services/app/WeiXin/DownLoadMedia", serverIds)
        if (this.isPath) {
          res = res.map((el) => el?.filePath)
        }
        return res
      } catch (e) {
        this.postLog(JSON.stringify(e))
        throw e
      }
    },
    upload: function (localId) {
      return new Promise((resolve, reject) => {
        getSdk().then((wx) => {
          wx.uploadImage({
            localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: async (res) => {
              resolve(res.serverId)
            },
            fail: (...args) => {
              this.$dialog.alert({
                message: "下载本地失败：" + JSON.stringify(args),
              })
              reject(args[0])
            },
          })
        })
      })
    },
    choose: async function () {
      const sourceType = this.sourceType ? ["camera", "album"] : ["camera"]
      let wx = await getSdk()

      wx.chooseImage({
        // count: Number(this.max) - this.value.length, //默认上传6张
        count: Number(this.max),
        sourceType: sourceType, // 可以指定来源是相册还是相机，默认二者都有
        success: (res) => {
          this.loading = true
          let promises = res.localIds.map((el) => {
            return this.upload(el)
          })
          Promise.all(promises)
            .then(async (serverIds) => {
              let res = await this.upload2Own(serverIds)
              this.$emit("input", this.value.concat(res))
              this.loading = false
            })
            .catch((reason) => {
              console.warn(JSON.stringify(reason))
              this.loading = false
            })
        },
        fail: (...args) => {
          this.$dialog.alert({
            message: JSON.stringify(args),
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
